<template>
  <div
    v-if="props.list?.length"
    class="relative"
    data-el="horizontal-list"
  >
    <div class="flex justify-between">
      <UiHeader
        v-if="props.header"
        v-bind="props"
        :header-style-class="`text-blue lg:${textColor}`"
      />
      <UiButton
        v-if="showButton"
        class="hidden xl:inline-flex"
        :class="textColor"
        variant="link"
        :uppercase="false"
        :to="props.button.href"
        icon-right="arrow"
      >
        {{ props.buttonText }}
      </UiButton>
    </div>

    <!-- MAIN PAGE mobile list -->
    <UiNewsList
      :list="props.list"
      :is-horizontal-list="true"
      :labels="props.labels"
      :size-variant="itemsPerPage === 5 ? 'dense' : 'comfort'"
      :title-background-class="background"
    />

    <UiButton
      v-if="showButton"
      class="mt-5 inline-flex xl:hidden"
      variant="link"
      :uppercase="false"
      :to="props.button.href"
      icon-right="arrow"
    >
      {{ props.buttonText }}
    </UiButton>
  </div>
</template>

<script lang="ts" setup>
import UiHeader from '@ui/components/UiHeader/UiHeader.vue'
import UiButton from '@ui/components/UiButton/UiButton.vue'
import UiNewsList from '@ui/components/UiNews/UiNewsList/UiNewsList.vue'
import type { UiHeaderProps } from '@ui/components/UiHeader/useUiHeader'
import type { CeNews, T3Button } from '../T3CeNews_pi1.types'
import { T3FrameAppearanceKey } from '../../T3Frame/T3Frame.types'
import { getTypo3HeaderColor } from '~/modules/rawlplug-cms/helpers/typo3-colors'

interface HorizontalListProps extends UiHeaderProps {
  button: T3Button | string
  buttonText: string
  labels?: {
    readTime: string
  } | null
  list: CeNews[] | null
  settings?: {
    pagination?: {
      itemsPerPage?: number | undefined
    } | undefined
  } | undefined
}

const props = withDefaults(defineProps<HorizontalListProps>(), {
  button: '',
  buttonText: '',
  header: '',
  headerLayout: 0,
  headerPosition: '',
  headerColor: '',
  labels: null,
  list: null,
  settings: () => {
    return {
      pagination: {
        itemsPerPage: 3,
      },
    }
  },
})

const textColor = computed(() => getTypo3HeaderColor(props.headerColor))
const { background } = inject(T3FrameAppearanceKey) || {}

const swiperNavigationColor = computed(() => {
  return textColor.value === 'text-white'
    ? 'white'
    : '#003366'
})

const showButton = computed((): boolean => {
  return Boolean(typeof props.button !== 'string' && props.button?.href && props.buttonText)
})

const itemsPerPage = computed((): number => {
  return props?.settings?.pagination?.itemsPerPage ?? 3
})
</script>

<style lang="postcss" scoped>
[data-el='horizontal-list'] {
  :deep(.swiper-button-wrapper-next),
  :deep(.swiper-button-wrapper-prev) {
    &:hover {
      border-color: v-bind(swiperNavigationColor) !important;
    }
    > span {
      background-color: v-bind(swiperNavigationColor) !important;
    }
  }
}
</style>
